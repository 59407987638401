@import '../../../../styles/customMediaQueries.css';

:root {
  /* --contentMaxWidth: 1600px; */
  --contentMaxWidthPages: calc(100vw - (92px*2));
  /* --carouselWidth: calc(100vw - (92px*2)) */
}

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 100px 64px; */
  padding: 64px 0 36px;
  background-color: var(--backgroundGray);
  
  @media (--viewportMedium) {
    padding: 100px 0 76px;
  }
  
  & [class*="SectionContainer_root"]:nth-of-type(odd) {
    background-color: var(--backgroundGray);
  }

  & [class*="SectionContainer_sectionContent"] {
    padding: 0;
    max-width: 1920px;
    margin: 0 auto;
  }

  & header {
    justify-content: flex-start;
    margin: 0 auto 16px;
    padding: 0 32px;
    max-width: unset;
    
    @media (--viewportMedium) {
      padding: 0 84px;
    }

    & h2 {
      font-size: 22px;
      color: var(--marketplaceSecondaryColor);
      max-width: 100%;
      padding: 0;
      
      @media (--viewportMedium) {
        font-size: 38px;
      }
    }
  }
}


:global(#clientsBlog) {

  & [class*="SectionCarousel_carouselContainer"] {
    padding-top: 16px;

    @media (--viewportMedium) {
      padding-top: 64px;
    }
  }

  & [class*="BlockContainer_root"] {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    overflow: hidden;
  }

  & img {
    border-radius: 8px 8px 0 0;
  }

  & [class*="BlockDefault_text"] {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    height: 100%;
    margin: 0;
    padding: 24px 22px 12px;
    border: 1px solid var(--marketplaceMiddleGreen);
    border-radius: 0 0 8px 8px;
    background-color: var(--colorWhite);

    & p:first-of-type {
      padding: 0;
      margin: 0;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.5px;
      color: var(--linksColor);
    }

    & ul {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      /* position: absolute;
      right: 22px;
      top: 24px; */
      margin: 0;
      /* padding: 0 0 0 75px; */
      list-style: none;

      display: none;

      & li {
        margin: 0 0 6px 12px;
        padding: 4px 12px;
        border-radius: 50px;
        background-color: var(--backgroundGray);
        font-size: 10px;
        line-height: 1.4;
      }
    }

    & h3 {
      padding: 0;
      margin: 12px 0 8px;
      font-size: 22px;
      line-height: 1.27;
      min-height: 60px;
    }

    & h4,
    & h4 a {
      margin: 0 0 12px;
      font-size: 14px;
      line-height: 20px;
      font-weight: var(--fontWeightMedium);
      color: var(--linksColor);
      text-decoration: none;
    }

    & p:last-of-type {
      padding: 0;
      margin: auto 0 16px;
      font-size: 14px;
      line-height: 20px;
      font-weight: var(--fontWeightRegular);
    }

    & a {
      margin: auto 0 0;
      padding: 0;
      color: var(--linksColor);
      text-decoration: underline;
      background-color: transparent;
      box-shadow: none;
      font-weight: var(--fontWeightSemiBold);

      &:hover,
      &:focus {
        color: var(--marketplaceDarkGreen);
      }

    }
  }
  
}