@import '../../../../styles/customMediaQueries.css';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    padding: 100px 64px;
  }
}

.rootIcon {
  fill: none;
}

.heading {
  margin: 0 0 32px;
  text-align: center;

  & .rootIcon {
    margin: 0 0 20px;
  }

  @media (--viewportMedium) {
    margin: 0 0 60px;
    
  
    & .rootIcon {
      margin: 0 0 32px;
    }
  }
}

.title {
  font-size: 22px;
  color: var(--marketplaceSecondaryColor);
  padding: 0;
  margin: 0;
  
  @media (--viewportMedium) {
    font-size: 38px;
  }
}

.info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 22px;
  max-width: 980px;
  margin: 0 auto; 

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.infoItem {
  padding: 22px 44px;
  background-color: var(--marketplaceLightGreen);
  border-radius: 8px;
  
  @media (--viewportMedium) {
    width: calc(50% - 11px);

    &:nth-child(1) {
      border-radius: 8px 8px 80px 8px;
    }
    &:nth-child(2) {
      border-radius: 8px 8px 8px 80px;
    }
    &:nth-child(3) {
      border-radius: 8px 80px 8px 8px;
    }
    &:nth-child(4) {
      border-radius: 80px 8px 8px 8px;
    }
  }

}

.infoTitle {
  margin: 22px 0 12px;
  padding: 0;

  font-size: 18px;
  line-height: 1.27;
  font-weight: var(--fontWeightBold);

  @media (--viewportMedium) {
    font-size: 22px;
  }
}

.infoDescription {
  padding: 0;
  margin: 0;
  font-weight: var(--fontWeightRegular);
}
