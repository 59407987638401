@import '../../styles/customMediaQueries.css';

.wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;
    max-width: 276px;
    width: 100%;
    
    @media (--viewportMedium) {
        max-width: 300px;
    }
}

.label {
    min-height: 32px;
}

.noItem {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    padding-left: 10px;
}

.selectInput {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 36px;

    input {
        border: 1px solid var(--colorDefaultGray);
        height: 100%;
        font-size: 14px;
        padding: 4px 12px 4px 12px;
        
        @media (--viewportMedium) {
            font-size: 16px;
            background-color: var(--colorWhite);
        }

        &:focus {
            box-shadow: none;
            border: 1px solid #b8bfd1;
        }
    }

}

.options {
    position: absolute;
    bottom: -5px;
    width: 100%;
    min-height: 40px;
    background-color: var(--colorWhite);
    border-radius: var(--borderRadiusMedium);
    border: 1px solid #d8dce6;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    transform: translate(0, 100%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 99999;
    max-height: 300px;
    overflow-y: auto;
    scrollbar-color: #cccccc #ffffff;
    scrollbar-width: thin;
    /* z-index: 1; */
}

.option {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    /* min-height: 40px; */
    cursor: pointer;
    padding-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 10px;

    &:hover {
        background-color: var(--marketplaceColor);
    }
}

.icon {
    position: absolute;
    right: 12px;
    top: 6px;
    cursor: pointer;
}