@import '../../../../styles/customMediaQueries.css';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 60px 24px 64px;
  background-color: var(--backgroundGray);

  @media screen and (min-width: 360px) {
    padding: 32px 24px 64px;
  }
  
  @media (--viewportMedium) {
    padding: 64px 84px;
  }
}

.titleHolder {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto 22px;
  text-align: center;
  
  @media (--viewportMedium) {
    text-align: left;
    margin: 0 auto;
  }
}

.title {
  font-size: 22px;
  color: var(--marketplaceSecondaryColor);
  padding: 0;
  margin: 0 0 8px;
  
  @media (--viewportMedium) {
    font-size: 38px;
  }
}

.info {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.375;
  letter-spacing: -0.5px;
}

.formRoot {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
  }
}

.option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  cursor: pointer;
  font-weight: 400;
  line-height: 20px;
  padding: 10px;

  &:hover {
    background-color: var(--marketplaceColor);
  }
}

.searchButton {
  display: flex;
  align-items: center;
  border-radius: var(--borderRadiusFull);
  border: 1px solid #d8dce6;
  cursor: pointer;
  padding: 7px 16px;
  height: 48px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 400;

  &:hover {
    border: 1px solid #b8bfd1;
    transition: all ease-in-out 100ms;
  }
}

.treatmentsRoot {
  width: 100%;
  padding-top: 54px;
  
  @media (--viewportMedium) {
    padding-top: 54px;
  }
}

.treatmentsContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 24px;
  row-gap: 24px;
  
  @media (--viewportSmall) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportMedium) {
    row-gap: 48px;
  }

  @media (--viewport1280) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.buttonContainer {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 20px;
}

.showButton {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 48px;
  cursor: pointer;
  width: auto;
  transition: all var(--transitionStyle);
  text-decoration: underline;

  font-size: 16px;
  font-weight: var(--fontWeightSemiBold);
  font-family: 'Sora', sans-serif;
  
  @media (--viewportMedium) {
    font-size: 24px;
    width: 100%;
    max-width: 212px;
  }
}

.showButton:hover,
.showButton:focus {

}
