@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(51, 90, 80, 0.4);
  border: 1px solid #ccc;
  transition: all .3s;
  overflow: hidden;

  &:hover {
    box-shadow: 0px 2px 6px 0px rgba(51, 90, 80, 0.40);
    border: 1px solid var(--marketplaceGreen);
    text-decoration: none;
  }
  
  &:focus {
    box-shadow: 0px 2px 6px 0px rgba(51, 90, 80, 0.40);
    border: 1px solid var(--marketplaceGreen);
    background-color: var(--marketplaceLightGreen);
    text-decoration: none;
  }
}

.image {
  width: 100%;
}

.title {
  margin: 22px 0 11px;
  padding: 0 12px;
  font-size: 16px;
  font-weight: 700;
  color: var(--marketplaceSecondaryColor);

  @media (--viewportMedium) {
    font-size: 22px;
  }
}

.description {
  font-size: 14px;
  line-height: 1.7;
  margin-top: 0;
  padding: 0 12px;
  font-weight: var(--fontWeightRegular);
  color: var(--colorBlack);
}
