@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */

  min-height: 397px;
  max-height: 397px;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media screen and (min-width: 360px) {
    min-height: 402px;
    max-height: 402px;
  }

  @media (--viewportMedium) {
    min-height: 430px;
    max-height: 430px;
  }

  @media (--viewportLarge) {
    min-height: 575px;
    max-height: 575px;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.backgroundImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 60% -30%, rgba(51, 90, 80, 0.9) 25%, transparent 65%);
  pointer-events: none;
  z-index: 1;
  /* Ensure the overlay doesn't interfere with interactions */
}

.sectionContent {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 32px 24px 30px;
  position: relative;
  word-break: break-word;
  z-index: 2;
  
  @media screen and (min-width: 360px) {
    padding: 32px 24px 60px;
  }

  @media (--viewportMedium) {
    padding: 64px 84px 110px;
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}

.infoContainer {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 810px;
  
  @media (--viewportMedium) {
    align-items: flex-start;
  }
}

.align {
  text-align: center;
  justify-self: center;

  @media (--viewportMedium) {
    text-align: left;
    justify-self: start;
  }
}

.title {
  margin: 0 0 8px;
  font-size: 38px;
  line-height: 1.1;
  color: var(--colorWhite);
  font-weight: var(--fontWeightLight);
  letter-spacing: -2.5px;
  font-family: 'Sora', sans-serif;

  @media screen and (min-width: 360px) {
    font-size: 44px;
    margin: 0 0 12px;
  }

  @media (--viewportMedium) {
    font-size: 48px;
    margin: 0 0 0;
  }
  @media (--viewportLarge) {
    font-size: 64px;
  }
}

.description {
  composes: align;
  margin: 0 0 22px;
  color: var(--colorWhite);
  max-width: 170px;
  
  font-size: 14px;
  line-height: 18px;
  font-weight: var(--fontWeightRegular);
  letter-spacing: -0.5px;
  
  @media screen and (min-width: 360px) {
    margin: 0 0 37px;
    font-size: 16px;
    line-height: 24px;
    max-width: 200px;
  }
  
  @media (--viewportMedium) {
    font-size: 24px;
    line-height: 1.1;
    margin: 0 0 32px;
    max-width: unset;
  }
}

.input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  padding: 0 48px 0 16px;
  transition: all var(--transitionStyle);
  font-weight: var(--fontWeightSemiBold);
  text-decoration: none;
  border: none;
  width: 100%;
  border-radius: var(--borderRadiusFull);
  background-color: var(--marketplaceColor);
  color: #154340;
  
  font-size: 10px;
  font-weight: var(--fontWeightRegular);
  letter-spacing: -0.5px;

  @media screen and (min-width: 360px) {
    font-size: 14px;
  }

  @media (--viewportMedium) {
    font-size: 16px;
    height: 44px;
  }
}

.input:focus,
.input:hover {
  outline: none;
  text-decoration: none;
  border: none;
  box-shadow: var(--boxShadowButton);
  background-color: var(--marketplaceColorDark);
}

.input::placeholder {
  font-weight: var(--fontWeightRegular);
  color: #154340;
}

.searchWrapper {
  position: relative;
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  
  @media screen and (min-width: 360px) {
    max-width: 320px;
  }
  
  @media (--viewportMedium) {
    max-width: 495px;
    margin: 0;
  }
}

.searchIcon {
  position: absolute;
  right: 12px;
  top: 5px;
  cursor: pointer;
  
  @media (--viewportMedium) {
    top: 12px;
  }
}

.searchWrapper:hover .input {
  outline: none;
  text-decoration: none;
  border: none;
  box-shadow: var(--boxShadowButton);
  background-color: var(--marketplaceColorDark);
}

.heroButtonMobile {
  border: 1px solid var(--colorWhite);
  margin: 36px 0 0;
  padding: 5px 17px;
  border-radius: 17px;

  font-size: 10px;
  font-weight: var(--fontWeightLight);
  line-height: 1;
  color: var(--colorWhite);
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  @media screen and (min-width: 360px) {
    margin: 27px 0 0;
  }

  @media (--viewportMedium) {
    display: none;
  }
}